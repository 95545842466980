export const hotelsItems = [
  {
    name: 'Grand Royal Hotel',
    cost: 180,
    location: 'Wembley, London',
    kmToCity: 2,
    reviewsCount: 80,
    rating: 4.5,
    image: 'https://images.unsplash.com/photo-1542314831-068cd1dbfeeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8aG90ZWx8ZW58MHx8MHx8&auto=format&fit=crop&w=400&q=60',
  },
  {
    name: 'Queen Hotel',
    cost: 200,
    location: 'Wembley, London',
    kmToCity: 2,
    reviewsCount: 74,
    rating: 45,
    image: 'https://images.unsplash.com/photo-1455587734955-081b22074882?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8aG90ZWx8ZW58MHx8MHx8&auto=format&fit=crop&w=400&q=60',
  },
  {
    name: 'Grand Royal Hotel',
    cost: 180,
    location: 'Wembley, London',
    kmToCity: 2,
    reviewsCount: 80,
    rating: 4.5,
    image: 'https://images.unsplash.com/photo-1566073771259-6a8506099945?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8aG90ZWx8ZW58MHx8MHx8&auto=format&fit=crop&w=400&q=60',
  },
  {
    name: 'Queen Hotel',
    cost: 200,
    location: 'Wembley, London',
    kmToCity: 2,
    reviewsCount: 74,
    rating: 45,
    image: 'https://images.unsplash.com/photo-1611892440504-42a792e24d32?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fGhvdGVsfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=400&q=60',
  },
  {
    name: 'Grand Royal Hotel',
    cost: 180,
    location: 'Wembley, London',
    kmToCity: 2,
    reviewsCount: 80,
    rating: 4.5,
    image: 'https://images.unsplash.com/photo-1520250497591-112f2f40a3f4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjR8fGhvdGVsfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=400&q=60',
  },
  {
    name: 'Queen Hotel',
    cost: 200,
    location: 'Wembley, London',
    kmToCity: 2,
    reviewsCount: 74,
    rating: 45,
    image: 'https://images.unsplash.com/photo-1551882547-ff40c63fe5fa?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzZ8fGhvdGVsfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=400&q=60',
  },
];

export const roomLuxParams = {
  chips: ['TV']
}

export const roomBizParams = {
  chips: ['TV']
}

// В каждом Lux номере у нас также есть
export const roomLuxEveryParams = {

}

// В каждом номере у нас также есть
// карточка в виде веера чипов?
export const roomEveryParams = {

}

/**
 * 1 lux
 * 2 lux
 * 3
 * 4 2 односпальных
 * 5
 * 6 lux
 * 7 lux
 * 8
 *
 *
 * 2 взрослых
 * телик
 * двух/одно спалка
 * столик
 */

function isLux(i) {
  return [1, 2, 6, 7].includes(i);
}

const reviews = [
  {
    user: 'Анна Петрова',
    date: '12 мая 2024',
    rating: 5,
    text: 'Прекрасный номер! Очень просторный и уютный. Кровать невероятно удобная, а вид из окна просто завораживает. Особенно понравилась ванная с джакузи. Обслуживание на высшем уровне. Обязательно вернусь снова!',
  },
  {
    user: 'Дмитрий Иванов',
    date: '23 апреля 2024',
    rating: 4,
    text: 'Номер действительно роскошный, все соответствует описанию. Единственный минус – слабый сигнал Wi-Fi в спальне. В остальном все отлично: чистота, комфорт, и отличный мини-бар. Рекомендую для отдыха.',
  },
  {
    user: 'Елена Смирнова',
    date: '5 июня 2024',
    rating: 5,
    text: 'Великолепный люкс! Все продумано до мелочей для максимального комфорта. Джакузи – просто мечта, а вид на город – потрясающий. Персонал очень вежливый и внимательный. Остались только положительные впечатления.',
  },
];

export const rooms = Array(8).fill(0).map((_, i) => i + 1).map(i => ({
  image: require(`./assets/rooms/${i}.jpg`),
  price: isLux(i) ? i * 400 + 6700 : i * 400 + 3400,
  rating: i % 2 === 0 ? '4.5' : '5.0',
  isLux: isLux(i),
  placesCount: 2,
  name: isLux(i) ? 'Люкс номер на двоих' : 'Номер на двоих',
  description: 'Люкс номер в отеле предлагает просторную гостиную, уютную спальню с кроватью king-size, современную ванную комнату с джакузи, мини-бар, бесплатный Wi-Fi и панорамный вид на город. Идеально подходит для комфортного и роскошного отдыха.',
  reviews,
}));

console.log(rooms)

export default {
  hotelsItems,
  roomLuxParams,
  roomBizParams,
  roomLuxEveryParams,
  roomEveryParams,
  rooms,
};
